<template>
  <div class="wrap-evnet-map">
    <div v-if="showVideoPath && wholeVideoPath.length > 0 && isBackPlay" class="wrap-gps-info">
      <div class="time-gps-items">
        <div class="just-at" :class="{'is-dropdown': isDropdown}" 
          @click="handleDropdown">
          <div class="time-gps">
            <div>
              <img src="@/assets/icons/time-solid.svg" alt="">
              {{ detectTime }}
            </div>
            <div>
              <img src="@/assets/icons/gps.svg" alt="">
              {{ gpsPosition.lat }}, {{ gpsPosition.lng }}
            </div>
          </div>
          <img :class="{ up: isDropdown }" src="@/assets/icons/Drop.svg" alt="">
        </div>
        <div v-if="isDropdown" class="dropdown-gps">
          <div ref="timeGps" class="time-gps" 
            :class="{ active: detectTime === formatTime(item.timestamp) }" 
            v-for="(item, index) in wholeVideoPath" :key="index"
            @click="setGpsItem(item)">
            <div>{{ formatTime(item.timestamp) }}</div>
            <div>{{ item.lat }}, {{ item.lng }}</div>
          </div>
        </div>
      </div>
      <div class="gps-location">
        <img 
          src="@/assets/icons/google-map-icon.png" 
          @click="goGoogleMap(gpsPosition.lat, gpsPosition.lng)"
          alt=""  
        >
      </div>
    </div>
    <div v-else-if="showVideoPath" class="one-gps-info">
      <div>
        <img src="@/assets/icons/gps.svg" alt="">
        {{ gpsPosition.lat }}, {{ gpsPosition.lng }}
      </div>
      <div class="gps-location">
        <img 
          src="@/assets/icons/google-map-icon.png" 
          @click="goGoogleMap(event.latitude, event.longitude)"
          alt=""  
        >
      </div>
    </div>
    <GmapMap
      ref="map"
      :center="center"
      :zoom="zoom"
      :options="mapOptions"
      class="gmap"
      :class="{ 'show-path': showVideoPath }"
      @tilesloaded="addMouseDownListener"
    >
      <!-- 若只要顯示mark -->
      <!-- <GmapMarker
        v-if="videoMarker.position && videoMarker.position.lat"
        :position="videoMarker.position"
        :clickable="true"
        :draggable="false"
        @click="center = videoMarker.position"
        :icon="getIcon(videoMarker.id)"
        :label="videoMarker.label"
      /> -->

      <!-- 若要標示裝置名稱改用下面component-->
      <GmapMarkerWithLabel
        v-if="gpsPosition"
        :position="gpsPosition"
        :icon="markIcon"
        :label-anchor="labelAnchor"
        :label-in-background="true"
        label-class="histroy-event-marker-label"
        :label-visible="true"
        :clickable="true"
        :draggable="false"
        @click="goGoogleMap"
      >
        <template #labelContent>
          <div class="event-map-content">
            <div class="info">
              <div class="event-map-colck-icon">
                <img src="@/assets/icons/clock-solid.svg" alt="">
              </div>
              {{ detectTime }}
            </div>
          </div>
        </template>
      </GmapMarkerWithLabel>
      <template v-if="showVideoPath">
        <gmap-polyline 
          :path="wholeVideoPath" 
          :options="wholePathOptions" 
        />
        <gmap-polyline 
          :path="videoPath" 
          :options="pathOptions" 
        />
      </template>
    </GmapMap>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import GmapMarkerWithLabel from '@/components/Base/MarkerLabel.vue'
import { formatTime } from '@/utils/lib.js'

export default {
  name: 'EventMap', 
  components: {
    GmapMarkerWithLabel
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'lpr', // lpr, fr, or, chased, sos, video
    },
    centerShift: {
      type: Boolean,
      default: false
    },
    showVideoPath: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      center: { lat: 24.807824129248665, lng: 121.03832092621745 },
      zoom: 15,
      labelAnchor: { x: 0, y: 80 },
      mapOptions: {
        mapTypeId: 'terrain',
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUI: false,
        draggable: false,
        styles: [
          {
            featureType: 'poi.business',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          }
        ]
      },
      wholePathOptions: {
        strokeOpacity: 0,
        icons: [{
          icon: {
            path: 'M 0,-1 0,1', // 此處是虛線的形狀。它實際上是一系列的短線段。
            strokeOpacity: 1,
            scale: 2.5,
            strokeColor: this.type === 'video' ? '#F99D41' : '#FFAAA6' // 設定虛線的顏色
          },
          offset: '0',
          repeat: '10px' // 設定每段虛線的長度
        }],
      },
      pathOptions: {
        strokeColor: this.type === 'video' ? '#F99D41' : '#F94144',
      },
      isDropdown: false,
    }
  },
  computed: {
    ...mapState('video', ['videoGpsList', 'isMapDraggable', 'isBackPlay']),
    ...mapGetters('video', ['videoPath', 'wholeVideoPath']),
    gpsPosition() {
      // sos事件的gps位置資料與其他事件不同, 多一層gps
      const eventGps = {}
      if (this.type === 'sos') {
        eventGps.lat = this.event.gps.latitude
        eventGps.lng = this.event.gps.longitude
      } else {
        eventGps.lat = this.event.latitude
        eventGps.lng = this.event.longitude
      }
      
      if (this.showVideoPath) {
        return this.videoPath.length > 0 ? this.videoPath[this.videoPath.length - 1] : eventGps
      }

      return eventGps
    },
    detectTime() {
      let eventTime = this.type === 'sos' 
        ? this.event.gps.timestamp ? this.event.gps.timestamp : this.event.startTime
        : this.event.detectTime
      
      if (this.type === 'video') eventTime = this.event.timestamp

      eventTime = formatTime(eventTime)

      if (this.showVideoPath) {
        return this.videoPath.length > 0 
          ? formatTime(this.videoPath[this.videoPath.length - 1].timestamp)
          : eventTime
      }

      return eventTime
    },
    isChasingEvent() {
      return this.event.chasing == 1 ||
        (this.event.chasingStartTime && this.event.chasingEndTime)
        ? true
        : false
    },
    markIcon() {
      const iconPaths = {
        lpr: this.isChasingEvent ? require('@/assets/icons/mark-red-hover.svg') 
          : require('@/assets/icons/mark-yellow-hover.svg'),
        fr: require('@/assets/icons/person-yellow-hover.svg'),
        or: require('@/assets/icons/OrPin_Big.svg'),
        chased: require('@/assets/icons/mark-red-hover.svg'),
        sos: require('@/assets/icons/mark-sos.svg'),
        video: require('@/assets/icons/VideoPin_Big.svg'),
      }

      return {
          url: iconPaths[this.type],
          scaledSize: { height: 84, width: 74, f: 'px', b: 'px' }
      }
    },

  },
  watch: {
    gpsPosition: {
      handler() {
        // 移動地圖到最新的位置點
        if (this.gpsPosition && !this.isMapDraggable) {
          if (this.centerShift) 
            this.$refs.map.panTo({ 
              lat: this.gpsPosition.lat + 0.002, 
              lng: this.gpsPosition.lng
            }) // 讓地圖的marker+時間往下位移在中間
          else this.$refs.map.panTo(this.gpsPosition) 
        }
      },
      deep: true
    },
    isMapDraggable: {
      handler() {
        if (!this.$refs.map?.$mapObject) return 
          
        this.$refs.map.$mapObject.setOptions({ draggable: this.isMapDraggable })
        // 移動地圖到設備的位置點
        if (!this.isMapDraggable) {
          this.$refs.map.panTo(this.gpsPosition)
          this.onDropdownCenter()
        }
      },
      immediate: true
    },

  },
  mounted() {
    this.$refs.map.$mapPromise.then(() => {
      // 移動地圖到最新的位置點
      if (this.gpsPosition)
        if (this.centerShift) 
          this.$refs.map.panTo({ 
            lat: this.gpsPosition.lat + 0.002, 
            lng: this.gpsPosition.lng
          }) // 讓地圖的marker+時間往下位移在中間
        else this.$refs.map.panTo(this.gpsPosition) 

      // 取得地圖 draggable 狀態, 並更新至 store  
      this.updateIsMapDraggable(this.$refs.map.$mapObject.draggable)
    })
  },
  methods: {
    ...mapMutations('video', ['updateIsMapDraggable', 'updatePlaySpecificTime']),
    formatTime,
    getZIndex(userId, index) {
      if (Object.keys(this.trackUser).length > 0) {
        if (userId == this.trackUser.id) {
          return this.markers.length
        }
        let p = this.markers.findIndex(mark => mark.id == this.trackUser.id)
        return (index > p) ? index : index + 1
      }
      return index + 1
    },
    zoomExtends() {
      // 判斷videoMarker是否有座標位置資料，有則移動地圖位置到該點
      // if (this.videoMarker.position && this.videoMarker.position.lat) {
      //   this.$refs.map.$mapObject.setZoom(15)
      //   this.$refs.map.panTo(this.videoMarker.position)
      // }
    },
    goGoogleMap() {
      const url = `https://www.google.com.tw/maps/search/?api=1&query=${this.gpsPosition.lat},${this.gpsPosition.lng}`
      window.open(url, '_blank')
    },
    handleDropdown() {
      this.isDropdown = !this.isDropdown
      this.onDropdownCenter()
    },
    onDropdownCenter() {
      if (this.isDropdown) {
        this.$nextTick(() => {
          const index = this.videoPath.length - 1
          if (index >= 0 && this.$refs.timeGps) {
            const el = this.$refs.timeGps[index]
            if (el) el.scrollIntoView({ behavior: 'smooth', block: 'center' })
          }
        })
      }
    },
    setGpsItem(item) {
      this.updatePlaySpecificTime(new Date(this.formatTime(item.timestamp)))
    },
    addMouseDownListener() {
      // 使用 $refs 取得地圖組件實例
      const mapInstance = this.$refs.map.$mapObject

      // 添加原生的 mousedown 監聽器
      mapInstance.addListener("mousedown", () => {
        this.enableDragging()
      })
    },
    enableDragging() {
      this.updateIsMapDraggable(true)
    },
  },
}
</script>

<style>
.histroy-event-marker-label {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.5px;
  padding: 12px 8px 12px;
  transform: translate(-51%, -100%);
}
</style>

<style lang="scss" scoped>
.wrap-evnet-map {
  width: 100%;
  height: 100%;
  position: relative;

  .no-map {
    outline: 1px solid #4A5C7880;
  }
}

.wrap-gps-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 9px;

  .time-gps-items {
    position: relative;
    width: 100%;
    min-height: 36px;
    margin-right: 4px;

    .just-at {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #39425D;
      border-radius: 8px;
      padding: 0px 12px 0px 20px;

      &.is-dropdown {
        border-radius: 8px 8px 0 0;
      }

      & > img {
        width: 20px;
        height: 20px;
        margin-left: 8px;
        transition: all .3s ease-in-out;
        &.up {
          transform: rotate(180deg);
        }
      }
    }

    .time-gps {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      min-height: 36px;
      & > div {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #ffffff;
        line-height: 21px;
        letter-spacing: 0.5px;
        margin-right: 24px;
        img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
      }
    }

    .dropdown-gps {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 124px;
      overflow: scroll;
      background: #39425D;
      border-radius: 0 0 8px 8px;
      border-top: 1px solid #ffffff33;
      z-index: 1;
      & .time-gps {
        padding-left: 48px;
        div:first-child {
          padding-right: 28px;
        }
        // padding-right: 56px;
        // div:last-child {
        //   padding-left: 28px;
        // }
        &:hover {
          background: #6E7D9380;
        }
        &.active {
          background: #6E7D93;
        }
      }
    }
  }
}

.gps-location {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  cursor: pointer;
  img {
    width: 20px;
    height: 20px;
  }
}

.one-gps-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  border-radius: 18px;
  padding: 0px 8px 0px 20px;
  background: #39425D;
  margin-bottom: 8px;
  font-size: 18px;
  color: #ffffff;
  & > div {
    display: flex;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }
}

.gmap {
  width: 100%;
  height: 100%;
  position: relative;
  &.show-path {
    width: 100%;
    height: calc(100% - 56px);
  }
}

.event-map-google-map {
  display: flex;
  background: #4A5C78;
  border-radius: 20px;
  padding: 4px 12px 4px 4px;
  margin-bottom: 10px;
}

.event-map-google-map .info {
  display: flex;
  align-items: center;
}

.event-map-content {
  position: relative;
  background:#282942b3; 
  padding: 8px 12px; 
  border-radius: 19px;
}

.event-map-content::after {
  content: '';
  position: absolute;
  left: 48%;
  bottom: -12px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 7px 0 7px;
  border-color: #282942b3 transparent transparent transparent;
}

.event-map-content .info {
  display: flex;
  align-items: center;
}

.event-map-google-map-icon {
  width: 30px;
  height: 30px;
  margin-right: 8px;
}

.event-map-colck-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.event-map-gps-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.event-map-google-map-icon img, 
.event-map-colck-icon img,
.event-map-gps-icon img
 {
  width: 100%;
  height: 100%;
}
</style>